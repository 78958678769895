import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page.component';
import { RepositoryService } from '../../services/repository/repository.service';
import { Legal } from 'src/models/Legal';
import { Api } from 'src/api/api';
import { NavController } from '@ionic/angular';

@Component({
	selector: 'app-form-send',
	templateUrl: './form-send.page.html',
	styleUrls: ['./form-send.page.scss'],
})
export class FormSendPage implements OnInit {
	static url = 'form-send';
	legal: Legal;

	constructor(
		private repository: RepositoryService,
		private router: Router,
		private route: ActivatedRoute,
		private navCtrl: NavController
	) {}

	static async navigate(router: Router) {
		await router.navigateByUrl(FormSendPage.url);
	}

	ngOnInit() {}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
