import { IonInput } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import Address from '../models/Address';
import Venue from '../models/Venue';
import { ValidationUtils } from './validation-utils';
import AutocompleteService = google.maps.places.AutocompleteService;
import AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;
import PlacesServiceStatus = google.maps.places.PlacesServiceStatus;
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import Geocoder = google.maps.Geocoder;
import GeocoderResult = google.maps.GeocoderResult;
import GeocoderStatus = google.maps.GeocoderStatus;
import LatLngBounds = google.maps.LatLngBounds;
import Map = google.maps.Map;
import Marker = google.maps.Marker;
import LatLng = google.maps.LatLng;
import ComponentRestrictions = google.maps.places.ComponentRestrictions;
import Utils from '../utils';
import { environment } from '../environments/environment';

const sessionToken = new AutocompleteSessionToken();

const componentForm = {
	street_number: 'short_name',
	route: 'long_name',
	locality: 'long_name',
	administrative_area_level_1: 'long_name',
	country: 'short_name',
	postal_code: 'short_name',
};
const componentRestrictions: ComponentRestrictions = {
	country: environment.countryList,
};
export const selectedMark = '/assets/icon/maps_marker_selected.svg';
export const notSelectedMark = '/assets/icon/maps_marker.svg';

export class MapsUtils {
	static async initAutocomplete(ionInput: IonInput, callback: (predictions: AutocompletePrediction[]) => void): Promise<Subscription> {
		const service = new AutocompleteService();
		return ionInput.ionChange.subscribe(event => {
			const value = event.detail.value;
			if (value.replace(' ', '').length === 0) {
				callback([]);
				return;
			}
			service.getPlacePredictions(
				{
					input: value,
					sessionToken,
					componentRestrictions,
				},
				async (predictions, status) => {
					if (status !== PlacesServiceStatus.OK) {
						callback([]);
						return;
					}
					callback(predictions);
				}
			);
		});
	}

	static async fillInPlace(
		translate: TranslateService,
		ionInput: IonInput,
		place: GeocoderResult,
		searchTerm: (searchTerm: string | null) => string
	): Promise<Address> {
		if (!place || !place.address_components) {
			return null;
		}
		const address = MapsUtils.placeToAddress(place);
		const addressError = ValidationUtils.validateAddress(translate, address);
		if (addressError) {
			console.error('Maps error: ' + addressError);
			throw addressError;
		}
		if (place.formatted_address) {
			ionInput.getInputElement().then(el => {
				el.value = place.formatted_address;
				searchTerm(el.value);
			});
		}
		address.lat = place.geometry.location.lat();
		address.lng = place.geometry.location.lng();
		return address;
	}

	static async executeSearch(ionInput: IonInput): Promise<GeocoderResult> {
		return new Promise<GeocoderResult>(async (resolve, reject) => {
			const input = await ionInput.getInputElement();
			const inputValue = input.value;
			if (!inputValue || inputValue.length <= 0) {
				reject();
				return;
			}
			const service = new AutocompleteService();
			service.getPlacePredictions(
				{
					input: input.value,
					sessionToken,
					componentRestrictions,
				},
				async (predictions, status) => {
					if (status !== PlacesServiceStatus.OK) {
						reject();
						return;
					}
					const prediction = predictions.find(
						pred => pred.types.findIndex(type => type === 'street_address' || type === 'route' || type === 'premise') >= 0
					);
					if (prediction) {
						try {
							resolve(await MapsUtils.getPlace(prediction.description));
						} catch (e) {
							reject(e);
						}
					} else {
						reject("No Predictions of type 'street_address', 'route' or 'premise'");
					}
				}
			);
		});
	}

	static async getPlace(pred: string): Promise<GeocoderResult> {
		return new Promise<GeocoderResult>((resolve, reject) => {
			const service = new Geocoder();
			service.geocode(
				{
					address: pred,
				},
				(result, status) => {
					if (status === GeocoderStatus.OK && result.length > 0) {
						resolve(result[0]);
						return;
					} else {
						reject(status);
						return;
					}
				}
			);
		});
	}

	static async getUserGeocode(): Promise<GeocoderResult> {
		const geocoderPromise = new Promise<GeocoderResult>((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				position => {
					new Geocoder().geocode(
						{
							location: new LatLng(position.coords.latitude, position.coords.longitude),
						},
						(results, status) => {
							if (status !== GeocoderStatus.OK) {
								reject(status);
								return;
							}
							resolve(results[0]);
							return;
						}
					);
				},
				error => reject(error)
			);
		});
		const timeoutPromise = new Promise<GeocoderResult>((resolve, reject) => {
			setTimeout(() => reject('Timeout'), 10000);
		});
		return Promise.race([geocoderPromise, timeoutPromise]);
	}

	static addVenuesToMap(markers: Marker[], selectedVenue: Venue, venues: Venue[], map: Map, onClick: (venue: Venue) => void): Marker[] {
		const newVenues = venues.filter(
			ven =>
				markers.find(
					mark =>
						ven &&
						ven.location &&
						ven.location.coordinates &&
						ven.location.coordinates[1] === mark.getPosition().lat() &&
						ven.location.coordinates[0] === mark.getPosition().lng()
				) === undefined
		);
		const marksToRemove = markers
			.map((mark, index) =>
				venues.find(
					ven =>
						ven &&
						ven.location &&
						ven.location.coordinates[1] === mark.getPosition().lat() &&
						ven.location.coordinates[0] === mark.getPosition().lng()
				) === undefined
					? index
					: -1
			)
			.filter(i => i >= 0);
		for (const markToRemove of marksToRemove.reverse()) {
			markers[markToRemove].setMap(null);
			Utils.removeFromArray(markers, markToRemove);
		}
		let venueIndex = 0;
		const bounds = new LatLngBounds();
		markers.forEach(mark => mark.setIcon(notSelectedMark));
		for (const venue of venues) {
			const latLng = Utils.getLatLng(venue);
			if (!latLng) {
				continue;
			}
			const position = new LatLng(latLng.latitude, latLng.longitude);
			if (latLng.latitude !== 0 && latLng.longitude !== 0) {
				bounds.extend(position);
			}
			if (newVenues.find(ven => ven._id === venue._id)) {
				const marker = new Marker({
					position,
					icon: notSelectedMark,
					animation: google.maps.Animation.DROP,
					clickable: true,
				});
				marker.addListener('click', () => {
					onClick(venue);
				});
				setTimeout(() => {
					marker.setMap(map);
				}, venueIndex * 20);
				markers.push(marker);
				venueIndex++;
			}
		}
		const markToSelect = markers.findIndex(
			mark =>
				selectedVenue &&
				selectedVenue.location &&
				selectedVenue.location.coordinates[1] === mark.getPosition().lat() &&
				selectedVenue.location.coordinates[0] === mark.getPosition().lng()
		);
		if (markToSelect >= 0) {
			markers[markToSelect].setIcon(selectedMark);
			const pos = markers[markToSelect].getPosition();
			const selectedBounds = new LatLngBounds();
			selectedBounds.extend(new LatLng(pos.lat() - 0.01, pos.lng() - 0.01));
			selectedBounds.extend(new LatLng(pos.lat() + 0.01, pos.lng() + 0.01));
			map.fitBounds(selectedBounds);
			map.panTo(pos);
		} else if (!bounds.isEmpty()) {
			map.fitBounds(bounds);
			map.panToBounds(bounds);
		} else {
			map.setZoom(6);
			map.panTo(new LatLng(51.0968582, 5.9690268));
		}
		return markers;
	}

	static placeToAddress(place: GeocoderResult): Address {
		const address = new Address();
		for (const component of place.address_components) {
			const addressType = component.types[0];
			if (componentForm[addressType]) {
				const val = component[componentForm[addressType]];
				switch (addressType) {
					case 'street_number':
						address.number = val;
						break;
					case 'route':
						address.street = val;
						break;
					case 'locality':
						address.city = val;
						break;
					case 'administrative_area_level_1':
						address.state = val;
						break;
					case 'country':
						address.country = val;
						break;
					case 'postal_code':
						address.postalCode = val;
						break;
					default:
						break;
				}
			}
		}
		address.lat = place.geometry.location.lat();
		address.lng = place.geometry.location.lng();
		return address;
	}

	static titleOfSuggestion(address: Address): string {
		let title = '';
		if (address.street) {
			title += address.street;
		}
		if (address.number) {
			title += ' ' + address.number;
		}
		if (title !== '' && (address.postalCode || address.city)) {
			title += ',';
		}
		if (address.postalCode) {
			if (title !== '') {
				title += ' ';
			}
			title += address.postalCode;
		}
		if (address.city) {
			if (title !== '') {
				title += ' ';
			}
			title += address.city;
		}

		return title;
	}
}

export const calculateGeoDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
	const r = 6371; // Radius of the earth in km
	const dLat = deg2rad(lat2 - lat1); // deg2rad below
	const dLon = deg2rad(lon2 - lon1);
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	return r * c; // Distance in km
};

export const deg2rad = (deg: number) => {
	return deg * (Math.PI / 180);
};
