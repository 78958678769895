import { ChangeDetectionStrategy, Component, Input, SimpleChanges } from '@angular/core';
import Article from '../../../models/Article';
import Utils from '../../../utils';
import { PreorderType } from '../../../enums/PreorderType';
import { OrderType } from '../../../enums/OrderType';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['./item-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemViewComponent {
	@Input() article: Article;
	@Input() currency: string;
	@Input() preorderType: PreorderType;

	utils = Utils;
	ot = OrderType;
	showFrom: boolean = false;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.article) {
			this.showFrom = this.isFromShow();
		}
	}

	isFromShow(): boolean {
		if (this.article.tags.length > 0) {
			for (const tag of this.article.tags) {
				if (tag.identifier === 'from_price') {
					return true;
				}
			}
		}
		return false;
	}
}
