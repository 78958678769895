import Article from '../models/Article';

export default class TagUtils {
	static hasEmptyTag(article: Article): boolean {
		return this.hasTag(article, 'empty');
	}

	static hasNewTag(article: Article): boolean {
		return this.hasTag(article, 'new');
	}

	static hasPopularTag(article: Article): boolean {
		return this.hasTag(article, 'popular');
	}

	static hasTopsellerTag(article: Article): boolean {
		return this.hasTag(article, 'topseller');
	}

	static hasExtraSlotsTag(article: Article): boolean {
		return this.hasTag(article, 'extra_slots');
	}

	static hasTag(article: Article, identifier: string): boolean {
		if (!article) {
			return false;
		}
		return article.tags.find(tag => tag.identifier === identifier) !== undefined;
	}
}
