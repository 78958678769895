import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { SuccessPage } from './success.page';
import { QRCodeModule } from 'angular2-qrcode';
import { SharedModuleModule } from '../../../shared-module/shared-module.module';

const routes: Routes = [
	{
		path: '',
		component: SuccessPage,
	},
];

@NgModule({
	imports: [CommonModule, IonicModule, RouterModule.forChild(routes), QRCodeModule, SharedModuleModule],
	declarations: [SuccessPage],
})
export class SuccessPageModule {}
