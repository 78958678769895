import { Component, EventEmitter, Input, Output } from '@angular/core';
import OptionGroup from '../../../models/OptionGroup';
import ArticleOption from '../../../models/ArticleOption';
import Utils from '../../../utils';
import { RepositoryService } from '../../services/repository/repository.service';
import Article from '../../../models/Article';
import { PreorderType } from '../../../enums/PreorderType';
import { OrderType } from '../../../enums/OrderType';

@Component({
	selector: 'app-toggle-button-group',
	templateUrl: './toggle-button-group.component.html',
	styleUrls: ['./toggle-button-group.component.scss'],
})
export class ToggleButtonGroupComponent {
	utils = Utils;
	ot = OrderType;

	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() preorderType: PreorderType;
	@Output() selection = new EventEmitter<ArticleOption>();

	constructor(public repository: RepositoryService) {}

	isSelected(article: Article) {
		return this.selected.findIndex(selected => selected.group === this.optionGroup._id && selected.article._id === article._id) >= 0;
	}

	select(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.group = this.optionGroup._id;
		option.quantity = 1;
		this.selection.emit(option);
	}
}
