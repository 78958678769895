import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-subway-card-modal',
	templateUrl: './subway-card-modal.component.html',
	styleUrls: ['./subway-card-modal.component.scss'],
})
export class SubwayCardModalComponent {
	constructor(private modalCtrl: ModalController) {}

	static async openModal(modalCtrl: ModalController): Promise<HTMLIonModalElement> {
		const modal = await modalCtrl.create({
			cssClass: 'subway-card-modal',
			component: SubwayCardModalComponent,
			backdropDismiss: true,
		});
		await modal.present();
		return modal;
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
