import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page.component';
import { RepositoryService } from '../../services/repository/repository.service';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.page.html',
	styleUrls: ['./faq.page.scss'],
})
export class FaqPage {
	static url = 'faq';

	constructor(
		private repository: RepositoryService,
		private router: Router
	) {}

	static navigate(router: Router) {
		router.navigateByUrl(FaqPage.url);
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
