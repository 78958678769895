import { Component, Input, OnInit } from '@angular/core';
import Tag from '../../../models/Tag';

@Component({
	selector: 'app-tags-banner',
	templateUrl: './tags-banner.component.html',
	styleUrls: ['./tags-banner.component.scss'],
})
export class TagsBannerComponent implements OnInit {
	@Input() tag: Tag;

	imageSource: string = '';

	constructor() {}

	ngOnInit() {
		if (this.tag) {
			switch (this.tag.identifier) {
				case 'new':
					this.imageSource = '../../../assets/tags/new.png';
					return;
				case 'vegetarian': {
					this.imageSource = '../../../assets/tags/veg.png';
					return;
				}
				default: {
					this.imageSource = '';
					return;
				}
			}
		}
	}
}
