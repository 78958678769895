import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import { register } from 'register-service-worker';

if (environment.production) {
	enableProdMode();
}

// register(environment.webAppUrl + '/service-worker.js', {
// 	registered() {
// 		console.log('Service worker has been registered.');
// 	},
// 	cached() {
// 		console.log('Content has been cached for offline use.');
// 	},
// 	updatefound() {
// 		console.log('New content is downloading.');
// 	},
// 	updated() {
// 		console.log('New content is available.');
// 		// @ts-ignore
// 		const version: string = environment.version;
// 		const usedVersion: string | null = localStorage.getItem('version');
// 		if (!usedVersion || version !== usedVersion) {
// 			localStorage.setItem('version', version);
// 			location.reload(true);
// 			console.log('Updated version.');
// 			return;
// 		}
// 	},
// 	offline() {
// 		console.log('No internet connection found. App is running in offline mode.');
// 	},
// 	error(error) {
// 		console.error('Error during service worker registration:', error);
// 	},
// });

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));
