import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import Utils from '../../../utils';
import RepositoryDirective from '../repository-directive';
import { TranslateService } from '@ngx-translate/core';
import { MapPage } from '../map/map.page';
import { MapsUtils } from '../../../utils/maps-utils';
import { Api } from '../../../api/api';
import { InformationModalComponent } from '../../components/information-modal/information-modal.component';
import { IonSlides, ModalController } from '@ionic/angular';

@Component({
	selector: 'app-home',
	templateUrl: 'home.page.html',
	styleUrls: ['home.page.scss'],
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';

	utils = Utils;
	loading = false;

	constructor(
		protected repository: RepositoryService,
		private translate: TranslateService,
		private router: Router,
		private snackBar: MatSnackBar,
		private cdr: ChangeDetectorRef,
		private modalCtrl: ModalController
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
		await router.navigateByUrl(HomePage.url);
	}

	ngOnInit() {
		super.ngOnInit();
		Api.getInformations().then(res => {
			// const infos = res.data;
			// const shown = this.repository.getShownInformations();
			// const filtered = infos.filter(info => shown.indexOf(info._id) === -1);
			res.data.forEach(info => InformationModalComponent.show(this.modalCtrl, info));
		});
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores() {
		this.loading = true;
		this.cdr.detectChanges();
		try {
			const address = MapsUtils.placeToAddress(await MapsUtils.getUserGeocode());
			this.repository.address.emit(address);
		} catch (e) {
			console.error(e);
			this.repository.address.emit(null);
		}
		await MapPage.navigate(this.router);
		this.loading = false;
		this.cdr.detectChanges();
	}
}
