import { Component, OnInit, ViewChild } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import ArticleCategory from '../../../models/ArticleCategory';
import { ActivatedRoute, Router } from '@angular/router';
import { ToOrderButtonComponent } from '../../components/to-order-button/to-order-button.component';
import { AlertController, IonSearchbar, ModalController } from '@ionic/angular';
import { ModalInfoComponent } from '../../components/modal-info/modal-info.component';
import Article from '../../../models/Article';
import Utils from '../../../utils';
import ArticleGroup from '../../../models/ArticleGroup';
import { AppComponent } from '../../app.component';
import { PreorderType } from '../../../enums/PreorderType';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderPage } from '../order/order.page';
import { HomePage } from '../home/home.page';
import RepositoryDirective from '../repository-directive';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../utils/order-utils';
import { OrderType } from '../../../enums/OrderType';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.page.component.html',
	styleUrls: ['./menu.page.component.scss'],
})
export class MenuPage extends RepositoryDirective implements OnInit {
	static url = 'menu';

	@ViewChild(ToOrderButtonComponent, { static: false }) toOrderButton;
	@ViewChild('categoryList', { static: false }) categoryList;
	@ViewChild('articleContent', { static: false }) articleList;
	@ViewChild(IonSearchbar, { static: false }) searchBar;

	selectedCategory: ArticleCategory = null;
	scrollEvents = true;
	loading = false;
	searchTerm = '';
	categories: ArticleCategory[] = [];

	isValid = false;
	utils = Utils;
	pt = PreorderType;
	differenceToMvo: number;
	attempt = 1;

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private router: Router,
		private analytics: AngularFireAnalytics
	) {
		super(repository);
		if (!this.repository.getStoredVenueId() || this.repository.getStoredVenueId() === null) {
			Utils.sleep(200).then(() => {
				this.snackbarCtrl
					.open(this.translate.instant('menu_page.venue_timed_out'), this.translate.instant('menu_page.venue_timed_out_action'))
					.onAction()
					.subscribe(() => {
						HomePage.navigate(this.router, this.repository);
					});
				this.loading = false;
			});
			return;
		}
		this.validate();
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(MenuPage.url);
	}

	ngOnInit(): void {
		super.ngOnInit();
		switch (this.route.snapshot.routeConfig.path) {
			case MenuPage.url:
				break;
			case OrderPage.url:
				break;
			case OrderPage.paymentCancelUrl:
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000,
				});
				break;
			case OrderPage.paymentFailUrl:
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000,
				});
				break;
		}
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	async scrollTo(index: number) {
		console.log('scrollTo(' + index + ')');
		if (this.categories.length - 1 < index || index < 0) {
			return;
		}
		if (this.searchTerm !== '') {
			const inputElement = await this.searchBar.getInputElement();
			inputElement.value = '';
			this.searchTerm = '';
			this.reloadCategories();
			await Utils.sleep(100);
			await this.scrollTo(index);
			return;
		}
		this.scrollEvents = false;
		this.selectedCategory = this.categories[index];
		console.log({
			categories: this.categories.map(cat => cat.name.de),
			selectedCategory: this.selectedCategory.name.de,
			index,
		});
		await this.scrollArticleListTo(index);
		await this.scrollCategoryListTo(index);
		this.scrollEvents = true;
	}

	async onScroll(event) {
		let categoryElements: HTMLElement[];
		if (this.articleList.el.children[0].className === 'items-wrap') {
			// All other browsers
			categoryElements = [...this.articleList.el.children];
		} else {
			// IE/Edge
			categoryElements = [...this.articleList.el.children[1].children];
		}
		const categoryIndex = categoryElements
			.map(el => el.offsetTop)
			.map((value, index, array) => {
				return (
					value <= event.detail.currentY &&
					((index < array.length - 1 && event.detail.currentY < array[index + 1]) || index === array.length - 1)
				);
			})
			.findIndex(value => value);
		let prevIndex = -1;
		if (this.selectedCategory) {
			prevIndex = this.categories.findIndex(category => category._id === this.selectedCategory._id);
		}
		if (prevIndex === categoryIndex) {
			return;
		}
		this.selectedCategory = this.categories[categoryIndex];
		await this.scrollCategoryListTo(categoryIndex);
	}

	async openModal(item: Article) {
		if (this.loading) {
			return;
		}
		const articleGroup = new ArticleGroup();
		articleGroup.article = item;
		articleGroup.groups.push(...Utils.defaultsToArticleOption(item, item.defaults, this.order.preorder.type));
		articleGroup.quantity = 1;
		const modal = await this.modalCtrl.create({
			cssClass: AppComponent.largeScreen ? 'large-modal' : '',
			component: ModalInfoComponent,
			componentProps: {
				articleGroup: JSON.parse(JSON.stringify(articleGroup)),
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response.data && response.data.articleGroup) {
			OrderUtils.addToOrder(this.order, response.data.articleGroup, this.analytics);
			this.repository.order.emit(this.order);
		}
	}

	isHidden(article: Article): boolean {
		return article.hidden || !Utils.getAvailability(article, OrderType.PREORDER, this.order.preorder.type);
	}

	onSearchTermChanged(event) {
		this.searchTerm = event.detail.value;
		this.reloadCategories();
	}

	async reloadCategories() {
		if (!this.venue || !this.order) {
			this.categories = [];
			return;
		}
		const lowerSearchTerm = this.searchTerm && this.searchTerm !== '' ? this.searchTerm.toLocaleLowerCase() : null;
		const allArticles: Article[] = [];
		this.venue.articleCategories.forEach(cat => allArticles.push(...cat.articles));
		this.categories = this.venue.articleCategories
			.filter(category => !category.hidden)
			.map(category => {
				const cat: ArticleCategory = JSON.parse(JSON.stringify(category));
				cat.articles = cat.articles.filter(article => {
					const available = Utils.isActive(allArticles, article, this.order.type, this.order.preorder.type);
					if (available && lowerSearchTerm) {
						const keys = [
							article.name.de.toLocaleLowerCase(),
							article.name.en.toLocaleLowerCase(),
							cat.name.de.toLocaleLowerCase(),
							cat.name.en.toLocaleLowerCase(),
						];
						return available && keys.map(key => key.indexOf(lowerSearchTerm)).find(result => result >= 0) !== undefined;
					}
					return available;
				});
				return cat;
			})
			.filter(category => category.articles.length > 0);
	}

	validate() {
		if (!this.venue || !this.order || !this.order.preorder) {
			this.isValid = false;
			return;
		}
		const mov = this.order.preorder.type === PreorderType.DELIVERY ? +this.venue.movDelivery.$numberDecimal : 0;
		const orderValue = OrderUtils.articleGroupsTotalPrice(
			this.order.orderedArticles,
			this.order.type,
			this.order.preorder.type,
			this.order.terminalorder ? this.order.terminalorder.type : null
		);
		this.differenceToMvo = orderValue - mov;
		this.isValid = this.differenceToMvo >= 0;
	}

	onVenue() {
		super.onVenue();
		this.reloadCategories().finally(() => {
			this.selectedCategory = this.venue && this.categories.length > 0 ? this.categories[0] : null;
			this.validate();
		});
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.snackbarCtrl.open(Utils.axiosErrorToMessage(this.translate, error), null, {
			duration: 2000,
		});
		this.attempt++;
		if (this.attempt > 5) {
			this.loading = false;
			this.snackbarCtrl.open(
				this.translate.instant('menu_page.venue_timed_out'),
				this.translate.instant('menu_page.venue_timed_out_action')
			);
		} else {
			this.repository.getVenue();
			return;
		}
		this.reloadCategories();
	}

	onOrder() {
		super.onOrder();
		if (!this.selectedCategory || !this.categories || this.categories.length === 0) {
			this.reloadCategories().finally(() => this.validate());
		} else {
			this.validate();
		}
	}

	private async scrollCategoryListTo(index: number) {
		console.log('scrollCategoryListTo(' + index + ')');
		if (
			index < 0 ||
			!this.categoryList ||
			!this.categoryList.nativeElement ||
			!this.categoryList.nativeElement.children[index] ||
			(!this.categoryList.nativeElement.scrollTo && !this.categoryList.nativeElement.scrollLeft)
		) {
			console.log('scrollCategoryListTo(' + index + ') cancelled');
			return;
		}
		if (this.categoryList.nativeElement.scrollTo) {
			// All other browsers
			this.categoryList.nativeElement.scrollTo(this.categoryList.nativeElement.children[index].offsetLeft - 25, 0);
		} else {
			// IE/Edge
			this.categoryList.nativeElement.scrollLeft = this.categoryList.nativeElement.children[index].offsetLeft - 25;
		}
		await Utils.sleep(100);
	}

	private async scrollArticleListTo(index: number) {
		if (this.articleList.el.children[index].tagName !== 'MAIN') {
			// All other browsers
			await this.articleList.scrollToPoint(0, this.articleList.el.children[index].offsetTop, 300);
		} else {
			// IE/Edge
			await this.articleList.scrollToPoint(0, this.articleList.el.children[1].children[index].offsetTop, 300);
		}
	}
}
