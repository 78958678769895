import { Component } from '@angular/core';
import { MenuPopoverComponent } from '../menu-popover/menu-popover.component';
import { PopoverController } from '@ionic/angular';

@Component({
	selector: 'app-burger-btn',
	templateUrl: './burger-btn.component.html',
	styleUrls: ['./burger-btn.component.scss'],
})
export class BurgerBtnComponent {
	visible: boolean;

	constructor(private popoverCtrl: PopoverController) {}

	async presentPopover(ev: any) {
		const popover = await this.popoverCtrl.create({
			component: MenuPopoverComponent,
			event: ev,
			translucent: true,
			showBackdrop: false,
			cssClass: 'menu-popover',
		});
		popover.onWillDismiss().then(() => {
			this.visible = false;
		});
		await popover.present();
		this.visible = false;
		return;
	}
}
