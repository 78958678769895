import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, Platform } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Api } from '../api/api';
import { MaintenancePage } from './pages/maintenance-page/maintenance.page';
import { SwUpdate } from '@angular/service-worker';
import firebase from 'firebase';
import { RepositoryService } from './services/repository/repository.service';
import { AnalyticsUtils } from '../utils/analytics-utils';
import Auth = firebase.auth.Auth;

declare var cookieConsentData: any;
declare var cookieConsentCallback: any;
declare var gtag: any;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	static largeScreen = false;
	static analyticsEnabled = false;

	constructor(
		private route: ActivatedRoute,
		private auth: AngularFireAuth,
		private alertCtrl: AlertController,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private analytics: AngularFireAnalytics,
		platform: Platform,
		private translate: TranslateService,
		private swUpdate: SwUpdate,
		private repository: RepositoryService
	) {
		cookieConsentCallback = async () => {
			console.log(cookieConsentData);
			await this.handleCookieConsent();
		};
		console.log(environment.customerGroup + ' ' + environment.version);
		AnalyticsUtils.logVersion(this.analytics);
		translate.setDefaultLang('de');
		translate.use('de');
		swUpdate.available.subscribe(() => {
			this.snackbarCtrl
				.open('Wir updaten grade deine App. Hab einen moment Geduld.', null, {
					duration: 2000,
				})
				.afterDismissed()
				.subscribe(() => {
					window.location.reload();
				});
		});
		if (swUpdate.isEnabled) {
			console.log('Checking for App update');
			swUpdate.checkForUpdate().finally(() => console.log('Update checking finished'));
		} else {
			swUpdate.activateUpdate().finally(async () => {
				console.log('Updates activated result: ' + swUpdate.isEnabled);
				if (swUpdate.isEnabled) {
					console.log('Checking for App update');
					await swUpdate.checkForUpdate();
				} else {
					console.log('SwUpdate is disabled (no service worker)');
				}
			});
		}
		platform.ready().then(() => {
			AppComponent.largeScreen = platform.width() >= 992;
			platform.resize.subscribe(() => {
				AppComponent.largeScreen = platform.width() >= 992;
			});
		});
		cookieConsentCallback();
		Api.isMaintenanceActive()
			.then(result => {
				if (result) {
					MaintenancePage.navigate(this.router);
				}
			})
			.catch(error => {
				console.log(error);
			});
	}

	ngOnInit() {}

	async handleCookieConsent() {
		if (cookieConsentData.strictlyNecessary) {
			this.auth
				.setPersistence(Auth.Persistence.LOCAL)
				.then(() => {
					this.auth.onAuthStateChanged(user => {
						if (user === null) {
							this.auth.signInAnonymously();
						} else {
							this.repository.user.emit(user);
						}
					});
				})
				.catch(err => console.error(err));
		}
		AppComponent.analyticsEnabled = cookieConsentData.targeting;
		await this.analytics.setAnalyticsCollectionEnabled(cookieConsentData.targeting);
		if (environment.customerAnalyticsId) {
			window['ga-disable-' + environment.customerAnalyticsId] = !cookieConsentData.targeting;
			if (cookieConsentData.targeting) {
				const s = 'script';
				const l = 'dataLayer';
				window[l] = window[l] || [];
				window[l].push({
					'gtm.start': new Date().getTime(),
					event: 'gtm.js',
				});
				const f = document.getElementsByTagName(s)[0];
				const j = document.createElement(s);
				const dl = l !== 'dataLayer' ? '&l=' + l : '';
				j.async = true;
				j.src = 'https://www.googletagmanager.com/gtm.js?id=' + environment.customerGTMId + dl;
				f.parentNode.insertBefore(j, f);
			}
		}
	}
}
