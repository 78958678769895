import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-health-banner',
	templateUrl: './health-banner.component.html',
	styleUrls: ['./health-banner.component.scss'],
})
export class HealthBannerComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
