import { environment } from '../environments/environment';
import axios, { AxiosPromise } from 'axios';
import Venue from '../models/Venue';
import Order from '../models/Order';
import Payment from '../models/Payment';
import Customer from '../models/Customer';
import MyOrdersResponse from '../models/MyOrdersResponse';
import Slot from '../models/Slot';
import ArticleCategory from '../models/ArticleCategory';
import OptionGroup from '../models/OptionGroup';
import { isMongoId } from 'validator';

import { customerBody, orderBody, paymentBody } from '../models/Converter';
import PromoCode from '../models/PromoCode';
import { Information } from '../models/Information';
import { AuthLoginResponse } from '../models/AuthLoginResponse';
import { Legal } from '../models/Legal';

const ORDER_SERVICE = '/v2/order/';
const GENERAL_SERVICE = '/v1/general/';
const SESSION_SERVICE = '/v1/session/';
const PAYMENT_SERVICE = '/v3/payment/';
const AUTH_SERVICE = '/v1/auth/';

const VENUE = 'venue/';
const LOGIN = 'login/';

const SESSION_ENDPOINT = SESSION_SERVICE;
const LOGIN_ENDPOINT = AUTH_SERVICE + LOGIN;
const VENUE_ENDPOINT = GENERAL_SERVICE + VENUE;
const ORDER_ENDPOINT = ORDER_SERVICE;
const TEST_ORDER_ENDPOINT = ORDER_SERVICE + 'test/';
const SLOT_ENDPOINT = ORDER_ENDPOINT + 'slot/';
const RESET_PASSWORD_ENDPOINT = AUTH_SERVICE + 'customer/firebase/reset';
const SEND_EMAIL_VERIFICATION_ENDPOINT = AUTH_SERVICE + 'customer/firebase/verifyEmail';
const DELETE_ACCOUNT_ENDPOINT = AUTH_SERVICE + 'customer';
const MAINTENANCE_ENDPOINT = GENERAL_SERVICE + 'maintenance/status';
const sanitizeId = (id: string) => {
	if (!id.startsWith(environment.customerGroup + '_') && !isMongoId(id)) {
		return environment.customerGroup + '_' + id;
	}
	return id;
};

class ApiService {
	axios = axios.create();

	constructor() {
		this.axios.defaults.baseURL = environment.baseUrl;
	}

	public authLogin(authLogin: { email: string; password: string }): AxiosPromise<AuthLoginResponse> {
		return this.axios.post(LOGIN_ENDPOINT, authLogin);
	}

	public createOrder(order: Order): AxiosPromise<Order> {
		return this.axios.post(ORDER_ENDPOINT, orderBody(order));
	}

	public createTestOrder(authToken: string, order: Order): AxiosPromise<{ order: Order; payment: string }> {
		if (environment.flavor === 'prodRelease') {
			order.flavor = 'prodDebug';
		}
		return this.axios.post(TEST_ORDER_ENDPOINT + order.venue, orderBody(order), {
			headers: {
				Authorization: 'Bearer ' + authToken,
			},
		});
	}

	public getAllVenues(): AxiosPromise<Venue[]> {
		return this.axios.get(VENUE_ENDPOINT + '?customerGroup=' + environment.customerGroup);
	}

	public getVenues(lat: number, lng: number, distance: number, articles: boolean): AxiosPromise<Venue[]> {
		return this.axios.get(
			VENUE_ENDPOINT + `?lat=${lat}&lng=${lng}&distance=${distance}&articles=${articles}&customerGroup=${environment.customerGroup}`
		);
	}

	public getVenuesBy(postalCode: string): AxiosPromise<Venue[]> {
		return this.axios.get(VENUE_ENDPOINT + '?deliveryPostalCode=' + postalCode);
	}

	public getLazyVenue(venueId: string): AxiosPromise<Venue> {
		return this.axios.get(VENUE_ENDPOINT + sanitizeId(venueId));
	}

	public getCategoriesWithArticles(venue: string, articles: boolean): AxiosPromise<ArticleCategory[]> {
		return this.axios.get(GENERAL_SERVICE + `articlecategory/?venue=${venue}&articles=${articles}`);
	}

	public getOptionGroupsByVenue(venue: string): AxiosPromise<OptionGroup[]> {
		return this.axios.get(GENERAL_SERVICE + 'articleoption/byvenue/' + venue);
	}

	public getCustomerOrders(userUid: string): AxiosPromise<MyOrdersResponse> {
		return this.axios.get(PAYMENT_SERVICE + 'customer/' + sanitizeId(userUid));
	}

	public async createPayment(payment: Payment): Promise<Payment> {
		payment._id = undefined;
		return (await this.axios.post(PAYMENT_SERVICE, paymentBody(payment))).data;
	}

	public async finishPaypalPayment(paypalOrderId: string): Promise<Payment> {
		return (await this.axios.patch(PAYMENT_SERVICE + paypalOrderId + '/paypal/pay')).data;
	}

	public getCustomer(userUid: string): AxiosPromise<Customer> {
		return this.axios.get(SESSION_SERVICE + 'customer/uuid/' + sanitizeId(userUid));
	}

	public getSlots(venue: string, from: string, to: string): AxiosPromise<Slot[]> {
		return this.axios.get(SLOT_ENDPOINT + venue + '?from=' + from + '&to=' + to);
	}

	public patchCustomer(userUid: string, customer: Customer): AxiosPromise<Customer> {
		customer._id = undefined;
		return this.axios.patch(SESSION_ENDPOINT + 'customer/uuid/' + sanitizeId(userUid), customerBody(customer));
	}

	public createCustomer(customer: Customer): AxiosPromise<Customer> {
		customer._id = undefined;
		return this.axios.post(SESSION_ENDPOINT + 'customer/', customerBody(customer));
	}

	public getOrder(order: string): AxiosPromise<Order> {
		return this.axios.get(ORDER_ENDPOINT + order);
	}

	public resetPassword(body: { email: string; customerGroup: string }): AxiosPromise {
		return this.axios.post(RESET_PASSWORD_ENDPOINT, body);
	}

	public sendEmailVerification(userUid: string): AxiosPromise {
		return this.axios.post(SEND_EMAIL_VERIFICATION_ENDPOINT, {
			userUid: sanitizeId(userUid),
		});
	}

	public deleteAccount(userUid: string): AxiosPromise {
		return this.axios.delete(DELETE_ACCOUNT_ENDPOINT + '/' + sanitizeId(userUid));
	}

	public applyPromoCode(order: Order): AxiosPromise<Order> {
		return this.axios.post(ORDER_ENDPOINT + 'promoCode', orderBody(order));
	}

	public getPromoCode(body: { code: string; venue: string }): AxiosPromise<PromoCode> {
		return this.axios.post(GENERAL_SERVICE + 'promoCode/code', body);
	}

	public isMaintenanceActive(): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			try {
				resolve((await this.axios.get(MAINTENANCE_ENDPOINT)).data);
			} catch (e) {
				reject(e);
			}
		});
	}

	public isSubCardNumberValid(cardNumber: string): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			try {
				resolve(await this.axios.get(ORDER_ENDPOINT + 'subcard/?cardNumber=' + cardNumber));
			} catch (e) {
				reject(e);
			}
		});
	}

	public async getSubCardNumberOf(email: string): Promise<string> {
		return (await this.axios.get(ORDER_ENDPOINT + 'subcard/?email=' + email)).data.subcard;
	}

	public getSubCard(num: string): AxiosPromise<{ balance: number; subcard: string }> {
		return this.axios.get(ORDER_ENDPOINT + 'subcard/?cardNumber=' + num);
	}

	public getInformations(): AxiosPromise<Information[]> {
		return this.axios.post(GENERAL_SERVICE + 'information/url', {
			url: window.location.hostname === 'localhost' ? 'dev-subway.smoothr.de' : window.location.hostname,
		});
	}

	public getPayment(payment: string): AxiosPromise<Payment> {
		return this.axios.get(PAYMENT_SERVICE + payment);
	}

	public getLegal(venue: string): AxiosPromise<Legal> {
		return this.axios.get(VENUE_ENDPOINT + venue + '/legal');
	}
}

export let Api = new ApiService();
export { PAYMENT_SERVICE, sanitizeId };
